/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PurchaseIndex } from '@amzn/merp-core/models/purchase';
import BigNumber from 'bignumber.js';

import { ExpandableCell } from 'modules/core/components/Table';
import { TableRow } from 'modules/core/model';
import { I18nFormatter } from 'modules/core/class';

interface PurchaseTotalCellProps {
    row: TableRow<PurchaseIndex>;
    style?: string;
}

export const PurchaseTotalCell = ({ row, style }: PurchaseTotalCellProps) => {
    const { t } = useTranslation('purchase');

    const mapToLineAmounts = (row: TableRow<PurchaseIndex>) => {
        const purchase = row.payload;

        return (
            <div>
                {purchase.purchaseLines?.map(({ lineAmount }, index) => {
                    return (
                        <React.Fragment key={index}>
                            <span className={style}>
                                {I18nFormatter.amount(t, lineAmount)} {purchase.currencyCode}
                            </span>
                            <br />
                        </React.Fragment>
                    );
                })}
            </div>
        );
    };

    return (
        <ExpandableCell
            cell={
                <span className={style}>
                    {I18nFormatter.amount(
                        t,
                        row.payload.requestAmount ||
                            row.payload.purchaseLines
                                ?.reduce((sum, { lineAmount }) => sum.plus(lineAmount), new BigNumber(0))
                                .toNumber()
                    )}{' '}
                    {row.payload.currencyCode}
                </span>
            }
            row={row}
            content={mapToLineAmounts(row)}
        />
    );
};
